.FlexDisplay {
  overflow: hidden !important;
  background-color: slategray;
  text-align: center;
  position: relative;
  height: 99.9%;
  width: 99.9%;
  margin-bottom: -0.4%;
}

.FlexVideo {
  height: 100%;
  width: 100%;
}
