@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 9Round colors */

:root {
  --niner-color: #d32f2f;
}

.text-9r-red {
  color: #d32f2f;
  color: var(--niner-color);
}

.btn-9r-red {
  background-color: #d32f2f;
  background-color: var(--niner-color);
  color: white;
}
.btn-9r-red {
  background-color: #d32f2f;
  background-color: var(--niner-color);
  color: white;
}

.btn-9r-red:hover,
.btn-9r-red:focus {
  background-color: #dc5959;
  color: white;
}
nav {
  font-size: 1.25rem;
}

nav h3 {
  font-size: 1rem;
  text-transform: uppercase !important;
}

.navbar .navbar-brand {
  font-size: 1.5rem;
}

.navbar-nav .nav-link {
  font-size: 1.25rem;
}

/* 9R Font */
@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir.eb3aa3d3.ttc);
}

@font-face {
  font-family: "Avenir Black";
  src: url("/static/media/Avenir Black.275de222.ttf");
}

@font-face {
  font-family: "Avenir Medium";
  src: url("/static/media/Avenir Medium.20905517.ttf");
}

/* Design */
.main {
  overflow: hidden;
}

.instructions {
  background-color: #c8102e;
  padding: 1% 0% 1% 1%;
  width: 101vw;
  height: 100vh;
  color: white;
  font-size: 2.7rem;
  font-family: "Avenir", "Roboto Mono", monospace;
  text-transform: uppercase;
  line-height: 2.9vh;
  overflow-x: hidden;
  letter-spacing: 1.5px;
  font-weight: bolder;
  text-shadow: 1px 1px 1px black;
}

.step {
  padding: 0% 3% 0.2% 2%;
  display: block;
}

.step-indent {
  margin-left: 1em;
  text-indent: -1.22em;
  line-height: 120%;
  margin-bottom: 0.8vh;
}

.sub-header {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: "Avenir Black", "Roboto Mono", monospace;
  font-weight: 900;
  font-size: 5rem;
  text-transform: uppercase;
  display: inline-block;
  color: #c8102e;
  line-height: 20%;
  margin: auto;
  padding: auto;
  position: relative;
  background-color: #ffff;
}

.no-sub-header {
  display: inline-block;
}

.spec-format {
  padding: 4% 5% 5% 4.5%;
}

/* Display Containers */
.screen-container {
  height: 101%;
  width: 101%;
  overflow: hidden;
}

.image-container {
  background-color: #c8102e;
  padding: 1% 0% 1.1% 0%;
  width: 100%;
  text-align: center;
}

/* Video Formatting */

.videos-3 .video-container {
  padding-top: 0.1%;
}

/* 3 Videos */
.videos-3 video {
  transform: scaleX(1.125);
  height: 99%;
  width: 82%;
  margin-bottom: -6.5%;
  padding-bottom: 0%;
  object-fit: contain;
  background-color: black;
}

/* 2 Videos */
.videos-2 video {
  height: 34.5vh;
  width: 110%;
  object-fit: contain;
  position: relative;
  right: 1%;
}

.videos-2 .video-container {
  margin: -0.3% 3.5% -0.7% 0%;
  max-height: 100vh;
  max-width: 101vw;
}

/* 1 Video */
.videos-1 video {
  height: 69vh;
  object-fit: fill;
  margin-top: -0.3%;
  position: relative;
  right: 26%;
}

.videos-1 .video-container {
  margin-bottom: -0.5%;
}

/* Authentication styles */
.screen-auth-code {
  background-color: lightgrey;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 3rem;
  letter-spacing: 7px;
  color: var(--niner-color);
  font-weight: bold;
  font-family: "Roboto Mono", monospace;
}

/** Media Queries & Dynamic Resolution **/

/* Full 4k */
@media screen and (max-width: 2160px) and (min-width: 1441px) and (orientation: portrait) {
  .main {
    max-height: 3840px;
    max-width: 2160px;
  }
  .title .sub-header {
    font-size: 8.6rem;
    margin-top: 1.5%;
    margin-bottom: 1.4%;
    padding-top: 2%;
    padding-bottom: 1.2%;
  }
  .title .no-sub-header {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin-left: -0.5%;
    margin-bottom: 0%;
    height: 23vh;
    width: 100%;
  }
  .videos-3 video {
    margin: 0% 0% 0% 9%;
    padding-bottom: 0%;
  }

  .instructions p {
    font-size: 5rem;
    margin-top: 1%;
  }
  .image-container {
    padding-bottom: 1.8%;
  }
}

/* Quad HD */
@media screen and (max-width: 1440px) and (min-width: 1081px) and (orientation: portrait) {
  .main {
    max-height: 2560px;
    max-width: 1440px;
  }
  .title .sub-header {
    font-size: 6rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .title .no-sub-header {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .videos-3 video {
    margin-top: -0.2%;
    margin-left: 10.55%;
    padding-bottom: 0%;
  }
  .videos-3 .video-container {
    margin-left: -2%;
    margin-top: 0.19%;
    margin-bottom: -0.2%;
    height: 23vh;
    width: 100%;
  }
  .videos-2 .video-container {
    padding-bottom: 0.5%;
    padding-top: 0.3%;
  }
  .instructions p {
    font-size: 3.3rem;
    margin-top: 1%;
  }
  .image-container {
    padding-bottom: 1.6%;
  }
}

/* Full HD */
@media screen and (max-width: 1080px) and (min-width: 721px) and (orientation: portrait) {
  .main {
    max-height: 1920px;
    max-width: 1080px;
  }
  .title .sub-header {
    font-size: 4.2rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.6%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin: -0.1% 0% -0.2% 1.3%;
    padding-bottom: 0%;
  }
  .videos-3 video {
    margin-left: 8%;
  }
  .videos-2 .video-container {
    padding-bottom: 0.3%;
    padding-top: 0.3%;
  }
  .videos-1 .video-container {
    margin-left: 2.4%;
  }
  .instructions p {
    font-size: 2.7rem;
    margin-top: 1%;
  }
}

/* Japan Spec */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .main {
    max-height: 1366px;
    max-width: 768px;
    overflow: hidden !important;
  }
  .title .sub-header {
    font-size: 2.8rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin: -0.1% 0% -0.2% 1.3%;
    padding-bottom: 0%;
  }
  .videos-3 video {
    margin-left: 8%;
    margin-bottom: -8%;
  }
  .videos-1 .video-container {
    margin-left: 2.4%;
  }
  .instructions p {
    font-size: 1.83rem;
    margin-top: 1%;
  }
}

/* SD */
@media screen and (max-width: 720px) and (orientation: portrait) {
  .main {
    max-height: 1280px;
    max-width: 720px;
  }
  .title .sub-header {
    font-size: 2.8rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin: -0.1% 0% -0.2% 1.3%;
    padding-bottom: 0%;
  }
  .videos-3 video {
    margin-left: 8%;
    margin-bottom: -8%;
  }
  .videos-1 .video-container {
    margin-left: 2.4%;
  }
  .instructions p {
    font-size: 1.7rem;
    margin-top: 1%;
  }
}

/* Landscape */
@media screen and (orientation: landscape) {
  .videos-1 video {
    height: 99%;
    width: 124%;
    margin-top: 0%;
    margin-bottom: 0.1%;
    margin-left: 0%;
  }
  .videos-1 .video-container {
    display: block;
    padding-left: 21%;
  }
  .videos-2 video {
    height: 95%;
    width: 99%;
    margin-top: 0%;
    margin-bottom: 0.1%;
  }
  .videos-3 video {
    height: 95%;
    width: 99%;
    margin-top: 0%;
    margin-bottom: 0.1%;
  }
  .video-container {
    display: contents;
  }
  .title .sub-header {
    font-size: 7vw;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .instructions p {
    font-size: 3.5vw;
    padding-top: 2%;
    padding-left: 1%;
    line-height: 150%;
  }
  .step {
    padding-bottom: 2%;
  }
}

/** Animation Elements  **/

/* Header Scroll */
.ticker {
  white-space: nowrap;
}

.no-ticker {
  white-space: nowrap;
  text-align: center;
}

.ticker span {
  display: inline-block;
  padding-left: 101%;
  -webkit-animation: scrollEffect 20s cubic-bezier(1, 1, 1, 1) infinite;
          animation: scrollEffect 20s cubic-bezier(1, 1, 1, 1) infinite;
}
@keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-webkit-keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Instructions Scroll */
.vertical-wrap {
  line-height: 3.5vh;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.vertical-scroll {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 33%;
  -webkit-animation: verticalScroll 20s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
          animation: verticalScroll 20s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
  display: inline-block;
}
@keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.flashingRed {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-color: darkred;
  z-index: 9999;
  -webkit-animation: flashing-red-screen 1000ms linear infinite;
  animation: flashing-red-screen 1000ms linear infinite;
}
@-webkit-keyframes flashing-red-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes flashing-red-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}

.flashingGreen {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-color: darkgreen;
  z-index: 9999;
  -webkit-animation: flashing-green-screen 1000ms linear infinite;
  animation: flashing-green-screen 1000ms linear infinite;
}
@-webkit-keyframes flashing-green-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes flashing-green-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}

.flashingYellow {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-color: goldenrod;
  z-index: 9999;
  -webkit-animation: flashing-yellow-screen 1000ms linear infinite;
  animation: flashing-yellow-screen 1000ms linear infinite;
}
@-webkit-keyframes flashing-yellow-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes flashing-yellow-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}

.screen-fx-hidden {
  display: none;
}

.screen-fx-image {
  height: 50%;
  width: 90%;
  margin-top: 40%;
}

.round-image {
  max-width: 70%;
  margin-top: -5%;
  margin-bottom: -5.5%;
}

.Timer-container {
  width: 14%;
  height: 6.5%;
  border-radius: 80px;
  font-size: 20px;
  line-height: 100px;
  text-align: center;
  margin-left: 75%;
  margin-top: 122%;
  z-index: 9999;
  position: absolute;
  display: flex;
}

.Timer-display {
  font-size: 6.5rem;
  margin-top: 10%;
  font-weight: bolder;
  text-shadow: 2px 2px 2px black;
}

.Timer-transition-2 {
  width: 28%;
  height: 20%;
  margin-left: 0.75%;
  margin-top: 100.8%;
  position: absolute;
  z-index: 999;
  object-fit: cover;
  -webkit-animation: active-break-transition 7000ms linear;
  animation: active-break-transition 7000ms linear;
}
@-webkit-keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.Timer-transition-1 {
  width: 28%;
  height: 20%;
  margin-left: 0.9%;
  margin-top: 99.9%;
  position: absolute;
  z-index: 999;
  object-fit: cover;
  -webkit-animation: active-break-transition-1 7000ms linear;
  animation: active-break-transition-1 7000ms linear;
}
@-webkit-keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@media screen and (max-width: 2160px) and (min-width: 1441px) and (orientation: portrait) {
  .Timer-display {
    font-size: 11rem;
    margin-top: 10%;
    font-weight: bolder;
    text-shadow: 2px 2px 2px black;
  }

  .Timer-transition-2 {
    width: 28%;
    height: 20%;
    margin-left: 0.75%;
    margin-top: 100%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition 7000ms linear;
    animation: active-break-transition 7000ms linear;
  }
  @-webkit-keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }

  .Timer-transition-1 {
    width: 28%;
    height: 20%;
    margin-left: 0.9%;
    margin-top: 100.3%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition-1 7000ms linear;
    animation: active-break-transition-1 7000ms linear;
  }
  @-webkit-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
}

@media screen and (max-width: 720px) and (orientation: portrait) {
  .Timer-display {
    font-size: 4rem;
    margin-top: 10%;
    font-weight: bolder;
    text-shadow: 2px 2px 2px black;
  }

  .Timer-transition-2 {
    width: 28%;
    height: 20%;
    margin-left: 0.75%;
    margin-top: 100.5%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition 7000ms linear;
    animation: active-break-transition 7000ms linear;
  }
  @-webkit-keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }

  .Timer-transition-1 {
    width: 28%;
    height: 20%;
    margin-left: 0.9%;
    margin-top: 100.4%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition-1 7000ms linear;
    animation: active-break-transition-1 7000ms linear;
  }
  @-webkit-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
}

/* Menu Design */
.menu-container {
  background-color: #27282b;
  border-radius: 2px;
  font-weight: bold;
}

.menu-design {
  color: rgba(255, 255, 255, 0.705);
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.menu-names {
  font-family: sans-serif;
  display: block;
  font-size: 1rem;
  padding-top: 16px;
  font-weight: bold;
}

.menu-help {
  color: gray;
  margin-top: 0.8rem;
  font-size: 1.3rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.menu-help:hover {
  color: whitesmoke;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.menu-dropdown {
  display: none;
  position: absolute;
  background-color: whitesmoke;
  min-width: 180px;
  box-shadow: 3px 5px 5px 2px rgba(0, 0, 0, 0.5);
  z-index: 2;
  font-size: 1rem;
  margin-top: 1vh;
  text-align: center;
  margin-left: -2vw;
  border-radius: 2px;
  opacity: 0.97;
}

.menu-dropdown-item {
  color: black;
  padding: 18px 18px;
  display: block;
}

.menu-show {
  display: block;
}

.menu-b

/* Menu Display */
.menu-user {
  display: inline-block;
  padding: 2%;
  padding-top: 6%;
}

.menu-admin {
  display: inline-block;
  padding: 2%;
}

.menu-super-admin {
  display: inline-block;
  padding: 2%;
  padding-top: 12px;
  padding-bottom: 0px;
}

/* Overide */
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  background-color: rgb(22, 24, 26);
}

.menu-container .nav-item,
.menu-container .nav-link {
  color: #d32f2f;
}

.menu-container .nav-link:hover {
  color: rgba(245, 245, 245, 0.829);
}

/* Media Queries*/

/* Medium Screens */
@media (max-width: 768px) {
  .menu-container {
    width: 100vw;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    text-align: center;
  }
  .menu-help {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .menu-container .nav-item i.far,
  .menu-container .nav-item i.fas {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .menu-adjust,
  .menu-user,
  .menu-admin {
    padding-top: 2%;
    padding: 1%;
  }
}

@media (max-width: 679px) {
  .menu-adjust,
  .menu-user,
  .menu-admin {
    border-right: none;
    padding-top: 2%;
    padding-bottom: 1%;
  }
}

/* Mobile */
@media (max-width: 392px) {
  .menu-names {
    font-size: 0rem;
  }
}

@media (max-width: 392px) {
  .menu-user {
    padding-top: 6%;
  }
}

@media (max-width: 392px) {
  .menu-admin {
    padding-top: 0%;
    padding-bottom: 0%;
  }
}

@media (max-width: 392px) {
  .menu-super-admin {
    padding-bottom: 0%;
    padding-top: 0%;
    display: block;
  }
}

/* Ex Small */
@media (max-width: 227px) {
  .menu-container .nav-item i.far,
  .menu-container .nav-item i.fas,
  .menu-design,
  .menu-user,
  .menu-admin,
  .menu-super-admin {
    font-size: 1.5rem;
  }
}

@media (max-width: 227px) {
  .menu-design {
    font-size: 0.8rem;
  }
}

/* Animations */
.menu-animation {
  -webkit-animation: openMenu linear 0.25s;
          animation: openMenu linear 0.25s;
}
@keyframes openMenu {
  0% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
  10% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
}
@-webkit-keyframes openMenu {
  0% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
  10% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
}

.menu-animation-after {
  -webkit-animation: closeMenu linear 0.25s;
          animation: closeMenu linear 0.25s;
}
@keyframes closeMenu {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  30% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  50% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  60% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  70% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  80% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
}
@-webkit-keyframes closeMenu {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  30% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  50% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  60% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  70% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  80% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
}

.menu-expanded {
  -webkit-animation: expandMenu linear 0.085s;
          animation: expandMenu linear 0.085s;
}
@-webkit-keyframes expandMenu {
  0% {
    opacity: 0.1;
    margin-right: 5w;
    transform: scale(0.1);
  }
  10% {
    opacity: 0.1;
    transform: scale(0.2);
  }
  20% {
    opacity: 0.2;
    transform: scale(0.3);
  }
  30% {
    opacity: 0.3;
    transform: scale(0.4);
  }
  40% {
    opacity: 0.4;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  60% {
    opacity: 0.6;
    transform: scale(0.7);
  }
  70% {
    opacity: 0.7;
    transform: scale(0.8);
  }
  80% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}
@keyframes expandMenu {
  0% {
    opacity: 0.1;
    margin-right: 5w;
    transform: scale(0.1);
  }
  10% {
    opacity: 0.1;
    transform: scale(0.2);
  }
  20% {
    opacity: 0.2;
    transform: scale(0.3);
  }
  30% {
    opacity: 0.3;
    transform: scale(0.4);
  }
  40% {
    opacity: 0.4;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  60% {
    opacity: 0.6;
    transform: scale(0.7);
  }
  70% {
    opacity: 0.7;
    transform: scale(0.8);
  }
  80% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}

.menu-expanded-after {
  -webkit-animation: retractMenu linear 0.085s;
          animation: retractMenu linear 0.085s;
}
@-webkit-keyframes retractMenu {
  0% {
    opacity: 1;
    padding: -5rem;
    transform: scale(0.9);
  }
  10% {
    opacity: 0.9;
    padding: -10rem;
    transform: scale(0.8);
  }
  20% {
    opacity: 0.8;
    padding: -20rem;
    transform: scale(0.7);
  }
  30% {
    opacity: 0.7;
    padding: -25rem;
    transform: scale(0.6);
  }
  40% {
    opacity: 0.6;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.4);
  }
  60% {
    opacity: 0.4;
    transform: scale(0.3);
  }
  70% {
    opacity: 0.3;
    transform: scale(0.2);
  }
  80% {
    opacity: 0.2;
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
  }
}
@keyframes retractMenu {
  0% {
    opacity: 1;
    padding: -5rem;
    transform: scale(0.9);
  }
  10% {
    opacity: 0.9;
    padding: -10rem;
    transform: scale(0.8);
  }
  20% {
    opacity: 0.8;
    padding: -20rem;
    transform: scale(0.7);
  }
  30% {
    opacity: 0.7;
    padding: -25rem;
    transform: scale(0.6);
  }
  40% {
    opacity: 0.6;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.4);
  }
  60% {
    opacity: 0.4;
    transform: scale(0.3);
  }
  70% {
    opacity: 0.3;
    transform: scale(0.2);
  }
  80% {
    opacity: 0.2;
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
  }
}

body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: black;
  text-shadow: 1px 1px whitesmoke;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #d32f2f;
  font-weight: bold;
}

.sidebar .nav-link:hover {
  color: #d32f2f;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

footer {
  position: absolute;
  bottom: -100px;
  left: 40%;
}

.preview-content {
  align-items: center !important;
}

.workout-item-wrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 1px 2px 8px !important;
}

.second-eye {
  color: #32127a !important;
}

.second-eye:hover {
  color: white !important;
  background-color: #32127a !important;
}

.SearchBar {
margin-left: auto;
padding-right: 1rem;

}

/* Override */

.SearchBar .form-control:focus {
  box-shadow: 0.2px 0.2px 0.2px 0.2px rgba(0.247, 0.247, 0.247, 0.247);
  outline: none;
}

.SearchBar .custom-select:focus {
  box-shadow: 0.2px 0.2px 0.2px 0.2px rgba(0.247, 0.247, 0.247, 0.247);
  outline: none;
}

.SearchBar .custom-select {
  border-radius: 0px;
}

/* Modal Background Overlay */
.modal-overlay-9r {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  background-color: rgb(0, 0, 0, 255);
  background-image: url("/logo_black.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-size: 10%;
  opacity: 0.8;
}

/* Default Bootstrap Overlay */
.modal-overlay-default {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  background-color: rgb(0, 0, 0, 255);
  background-image: none;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .modal-overlay-9r {
    background-size: 20%;
    background-position-x: 98%;
    background-position-y: 98%;
  }
}

/* Modal Show */
.modal-show-9r {
  position: fixed;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  z-index: 2000;
  max-height: 600px;
  max-width: 500px;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  margin-top: 8.5vh;
}

@media (max-width: 768px) {
  .modal-show-9r .modal-overlay-9r {
    max-height: 90vh;
    max-width: 90vw;
  }
}

/* Modal Children */
.modal-header-9r {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding: 1rem 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title-9r {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 490;
  font-size: 1.6rem;
  text-decoration: none;
  padding: 0px;
}

.modal-body-9r {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-footer-9r {
  display: flex;
  flex-wrap: wrap;
  align-items: right;
  justify-content: flex-start;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

/* Close Button labeled*/
.modal-close-9r {
  position: absolute;
  bottom: 11px;
  right: 12px;
}

/* Close Button unlabeled */
.modal-button-9r {
  background-color: white;
  border: none;
  outline: none;
  position: absolute;
  margin: 7px;
  font-size: 26px;
  color: rgb(126, 126, 126);
  top: 0;
  right: 0;
}

.modal-button-9r:hover {
  color: rgb(56, 56, 56);
  font-weight: bolder;
}

/* Modal & Open Animation */
.modal-show-before-9r {
  -webkit-animation: openModal linear 0.15s;
          animation: openModal linear 0.15s;
}
@keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}
@-webkit-keyframes openModal {
  0% {
    opacity: 0.1;
    transform: translateX(-100px);
  }
  25% {
    opacity: 0.3;
    transform: translateX(-75px);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }
  75% {
    opacity: 0.7;
    transform: translateX(-25px);
  }
  100% {
    opacity: 0.9;
    transform: translateX(-5px);
  }
}

/* Modal & Close Animation */
.modal-show-after-9r {
  -webkit-animation: closeModal linear 0.15s;
          animation: closeModal linear 0.15s;
}
@keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}
@-webkit-keyframes closeModal {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  25% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50px);
  }
  75% {
    opacity: 0.3;
    transform: translateY(-75px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-100px);
  }
}

/* Modal & Spring Animation*/
.modal-spring-9r {
  -webkit-animation: springModal linear 0.15s;
          animation: springModal linear 0.15s;
}
@keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes springModal {
  0% {
    transform: translateX(-1px) scale(1.02);
  }
  25% {
    transform: translateX(1px) scale(1.04);
  }
  50% {
    transform: translateX(-2px) scale(1.05);
  }
  75% {
    transform: translateX(2px) scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

/* Alert */
.alert-button {
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
}

.alert-button:hover {
  transform: scale(1.14);
  -webkit-transform: scale(1.14);
  -o-transform: scale(1.14);
  -moz-transform: scale(1.14);
  -ms-transform: scale(1.14);
}

.alert-button-box {
  color: white;
  opacity: 0.5;
  position: absolute;
  font-family: sans-serif;
  padding: auto;
}

.alert-icon {
  font-size: 20px;
  left: 1;
  top: 2;
}

.alert-icon-message {
  font-family: sans-serif;
  font-size: 15px;
}

.alert-message {
  margin-bottom: 5px;
  margin-top: 5px;
}

.alert-box {
  opacity: 0.9;
  display: grid;
  grid-template-rows: [row1-start] 100% [row1-end row2-start] 100% [row2-end];
  grid-template-columns:
    310px [col-start] calc(100% - 5px) [col-start] calc(100% - 5px)
    [col-start];
}

/* Alert Container */

.alert-container {
  z-index: 999;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  display: absolute;
}

.alert-container-list {
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
}

/* Animations */

.alert-animation {
  box-shadow: 1px 2px lightgray;
  border-radius: 2px;
  -webkit-animation: fadeIn linear 0.2s;
          animation: fadeIn linear 0.2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

.alert-animation-after {
  -webkit-animation: fadeOut linear 0.2s;
          animation: fadeOut linear 0.2s;
}
@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

.card-v {
  border-radius: 2px;
  background: rgb(240, 240, 240);
  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.05), 0 0 6px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.5rem;
  margin-left: 5%;
  padding: 0.1rem 1rem 0.5rem 1rem;
  font-size: large;
  text-align: left;
  width: 90%;
  display: block;
  position: relative;
  max-height: 4rem;
  overflow-y: hidden;
  opacity: 0.7;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.card-v:hover {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3), 1px 2px 3px rgba(0, 0, 0, 0.04);
  opacity: 1;
}

.card-expand {
  overflow-y: show;
  position: relative;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  opacity: 1;
  padding: 0.1rem 1rem 0.5rem 1rem;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3), 1px 2px 3px rgba(0, 0, 0, 0.04);
}

.card-version {
  font-weight: 500;
  font-size: small;
  padding-bottom: 5vh;
}

.card-details {
  padding-top: 0.1rem;
}

.card-title {
  font-weight: bold;
  font-size: larger;
}

.card-description {
  font-size: large;
}

.card-expand > div > .card-description {
  text-decoration: underline;
  font-size: large;
}

.card-desc-details {
  font-size: large;
  display: inline;
}

.card-hidden {
  display: none;
}

/* Animations */
.card-expand-before {
  -webkit-animation: expandCard linear 0.3s;
          animation: expandCard linear 0.3s;
}
@-webkit-keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}
@keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}

.card-expand-after {
  -webkit-animation: retractCard linear 0.1s;
          animation: retractCard linear 0.1s;
}
@-webkit-keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}
@keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}


.root {
  position: relative;
}

.center {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .root {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
} */

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  text-align: center;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.FlexDisplay {
  overflow: hidden !important;
  background-color: slategray;
  text-align: center;
  position: relative;
  height: 99.9%;
  width: 99.9%;
  margin-bottom: -0.4%;
}

.FlexVideo {
  height: 100%;
  width: 100%;
}

