/* Menu Design */
.menu-container {
  background-color: #27282b;
  border-radius: 2px;
  font-weight: bold;
}

.menu-design {
  color: rgba(255, 255, 255, 0.705);
  font-size: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.menu-names {
  font-family: sans-serif;
  display: block;
  font-size: 1rem;
  padding-top: 16px;
  font-weight: bold;
}

.menu-help {
  color: gray;
  margin-top: 0.8rem;
  font-size: 1.3rem;
  user-select: none;
}

.menu-help:hover {
  color: whitesmoke;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.menu-dropdown {
  display: none;
  position: absolute;
  background-color: whitesmoke;
  min-width: 180px;
  box-shadow: 3px 5px 5px 2px rgba(0, 0, 0, 0.5);
  z-index: 2;
  font-size: 1rem;
  margin-top: 1vh;
  text-align: center;
  margin-left: -2vw;
  border-radius: 2px;
  opacity: 0.97;
}

.menu-dropdown-item {
  color: black;
  padding: 18px 18px;
  display: block;
}

.menu-show {
  display: block;
}

.menu-b

/* Menu Display */
.menu-user {
  display: inline-block;
  padding: 2%;
  padding-top: 6%;
}

.menu-admin {
  display: inline-block;
  padding: 2%;
}

.menu-super-admin {
  display: inline-block;
  padding: 2%;
  padding-top: 12px;
  padding-bottom: 0px;
}

/* Overide */
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  background-color: rgb(22, 24, 26);
}

.menu-container .nav-item,
.menu-container .nav-link {
  color: #d32f2f;
}

.menu-container .nav-link:hover {
  color: rgba(245, 245, 245, 0.829);
}

/* Media Queries*/

/* Medium Screens */
@media (max-width: 768px) {
  .menu-container {
    width: 100vw;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    text-align: center;
  }
  .menu-help {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .menu-container .nav-item i.far,
  .menu-container .nav-item i.fas {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .menu-adjust,
  .menu-user,
  .menu-admin {
    padding-top: 2%;
    padding: 1%;
  }
}

@media (max-width: 679px) {
  .menu-adjust,
  .menu-user,
  .menu-admin {
    border-right: none;
    padding-top: 2%;
    padding-bottom: 1%;
  }
}

/* Mobile */
@media (max-width: 392px) {
  .menu-names {
    font-size: 0rem;
  }
}

@media (max-width: 392px) {
  .menu-user {
    padding-top: 6%;
  }
}

@media (max-width: 392px) {
  .menu-admin {
    padding-top: 0%;
    padding-bottom: 0%;
  }
}

@media (max-width: 392px) {
  .menu-super-admin {
    padding-bottom: 0%;
    padding-top: 0%;
    display: block;
  }
}

/* Ex Small */
@media (max-width: 227px) {
  .menu-container .nav-item i.far,
  .menu-container .nav-item i.fas,
  .menu-design,
  .menu-user,
  .menu-admin,
  .menu-super-admin {
    font-size: 1.5rem;
  }
}

@media (max-width: 227px) {
  .menu-design {
    font-size: 0.8rem;
  }
}

/* Animations */
.menu-animation {
  animation: openMenu linear 0.25s;
}
@keyframes openMenu {
  0% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
  10% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
}
@-moz-keyframes openMenu {
  0% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
  10% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
}
@-o-keyframes openMenu {
  0% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
  10% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
}
@-webkit-keyframes openMenu {
  0% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
  10% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
}

.menu-animation-after {
  animation: closeMenu linear 0.25s;
}
@keyframes closeMenu {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  30% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  50% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  60% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  70% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  80% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
}
@-moz-keyframes closeMenu {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  30% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  50% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  60% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  70% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  80% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
}
@-o-keyframes closeMenu {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  30% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  50% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  60% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  70% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  80% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
}
@-webkit-keyframes closeMenu {
  0% {
    opacity: 0.9;
    transform: translateY(-5px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-25px);
  }
  30% {
    opacity: 0.6;
    transform: translateY(-50px);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-75px);
  }
  50% {
    opacity: 0.4;
    transform: translateY(-100px);
  }
  60% {
    opacity: 0.3;
    transform: translateY(-125px);
  }
  70% {
    opacity: 0.2;
    transform: translateY(-150px);
  }
  80% {
    opacity: 0.1;
    transform: translateY(-175px);
  }
  100% {
    opacity: 0.1;
    transform: translateY(-200px);
  }
}

.menu-expanded {
  animation: expandMenu linear 0.085s;
}
@keyframes expandMenu {
  0% {
    opacity: 0.1;
    margin-right: 5w;
    transform: scale(0.1);
  }
  10% {
    opacity: 0.1;
    transform: scale(0.2);
  }
  20% {
    opacity: 0.2;
    transform: scale(0.3);
  }
  30% {
    opacity: 0.3;
    transform: scale(0.4);
  }
  40% {
    opacity: 0.4;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  60% {
    opacity: 0.6;
    transform: scale(0.7);
  }
  70% {
    opacity: 0.7;
    transform: scale(0.8);
  }
  80% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}

.menu-expanded-after {
  animation: retractMenu linear 0.085s;
}
@keyframes retractMenu {
  0% {
    opacity: 1;
    padding: -5rem;
    transform: scale(0.9);
  }
  10% {
    opacity: 0.9;
    padding: -10rem;
    transform: scale(0.8);
  }
  20% {
    opacity: 0.8;
    padding: -20rem;
    transform: scale(0.7);
  }
  30% {
    opacity: 0.7;
    padding: -25rem;
    transform: scale(0.6);
  }
  40% {
    opacity: 0.6;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.4);
  }
  60% {
    opacity: 0.4;
    transform: scale(0.3);
  }
  70% {
    opacity: 0.3;
    transform: scale(0.2);
  }
  80% {
    opacity: 0.2;
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
  }
}
