.preview-content {
  align-items: center !important;
}

.workout-item-wrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 1px 2px 8px !important;
}

.second-eye {
  color: #32127a !important;
}

.second-eye:hover {
  color: white !important;
  background-color: #32127a !important;
}
