.Timer-container {
  width: 14%;
  height: 6.5%;
  border-radius: 80px;
  font-size: 20px;
  line-height: 100px;
  text-align: center;
  margin-left: 75%;
  margin-top: 122%;
  z-index: 9999;
  position: absolute;
  display: flex;
}

.Timer-display {
  font-size: 6.5rem;
  margin-top: 10%;
  font-weight: bolder;
  text-shadow: 2px 2px 2px black;
}

.Timer-transition-2 {
  width: 28%;
  height: 20%;
  margin-left: 0.75%;
  margin-top: 100.8%;
  position: absolute;
  z-index: 999;
  object-fit: cover;
  -webkit-animation: active-break-transition 7000ms linear;
  -moz-animation: active-break-transition 7000ms linear;
  -ms-animation: active-break-transition 7000ms linear;
  -o-animation: active-break-transition 7000ms linear;
  animation: active-break-transition 7000ms linear;
}
@-webkit-keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@-moz-keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@-o-keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -o-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@-ms-keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@keyframes active-break-transition {
  0% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 14.3%;
    height: 69%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -webkit-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}

.Timer-transition-1 {
  width: 28%;
  height: 20%;
  margin-left: 0.9%;
  margin-top: 99.9%;
  position: absolute;
  z-index: 999;
  object-fit: cover;
  -webkit-animation: active-break-transition-1 7000ms linear;
  -moz-animation: active-break-transition-1 7000ms linear;
  -ms-animation: active-break-transition-1 7000ms linear;
  -o-animation: active-break-transition-1 7000ms linear;
  animation: active-break-transition-1 7000ms linear;
}
@-webkit-keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@-moz-keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -moz-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@-o-keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -o-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@-ms-keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@keyframes active-break-transition-1 {
  0% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  90% {
    margin-top: 13.8%;
    height: 68.8%;
    width: 100%;
    margin-left: 0%;
    object-fit: cover;
    z-index: 999;
    position: absolute;
  }
  100% {
    -webkit-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}

@media screen and (max-width: 2160px) and (min-width: 1441px) and (orientation: portrait) {
  .Timer-display {
    font-size: 11rem;
    margin-top: 10%;
    font-weight: bolder;
    text-shadow: 2px 2px 2px black;
  }

  .Timer-transition-2 {
    width: 28%;
    height: 20%;
    margin-left: 0.75%;
    margin-top: 100%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition 7000ms linear;
    -moz-animation: active-break-transition 7000ms linear;
    -ms-animation: active-break-transition 7000ms linear;
    -o-animation: active-break-transition 7000ms linear;
    animation: active-break-transition 7000ms linear;
  }
  @-webkit-keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-moz-keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -moz-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-o-keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-ms-keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition {
    0% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.65%;
      height: 68.2%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }

  .Timer-transition-1 {
    width: 28%;
    height: 20%;
    margin-left: 0.9%;
    margin-top: 100.3%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition-1 7000ms linear;
    -moz-animation: active-break-transition-1 7000ms linear;
    -ms-animation: active-break-transition-1 7000ms linear;
    -o-animation: active-break-transition-1 7000ms linear;
    animation: active-break-transition-1 7000ms linear;
  }
  @-webkit-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-moz-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -moz-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-o-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-ms-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition-1 {
    0% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.12%;
      height: 68.8%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
}

@media screen and (max-width: 720px) and (orientation: portrait) {
  .Timer-display {
    font-size: 4rem;
    margin-top: 10%;
    font-weight: bolder;
    text-shadow: 2px 2px 2px black;
  }

  .Timer-transition-2 {
    width: 28%;
    height: 20%;
    margin-left: 0.75%;
    margin-top: 100.5%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition 7000ms linear;
    -moz-animation: active-break-transition 7000ms linear;
    -ms-animation: active-break-transition 7000ms linear;
    -o-animation: active-break-transition 7000ms linear;
    animation: active-break-transition 7000ms linear;
  }
  @-webkit-keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-moz-keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -moz-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-o-keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-ms-keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition {
    0% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.5%;
      height: 68.7%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }

  .Timer-transition-1 {
    width: 28%;
    height: 20%;
    margin-left: 0.9%;
    margin-top: 100.4%;
    position: absolute;
    z-index: 999;
    object-fit: cover;
    -webkit-animation: active-break-transition-1 7000ms linear;
    -moz-animation: active-break-transition-1 7000ms linear;
    -ms-animation: active-break-transition-1 7000ms linear;
    -o-animation: active-break-transition-1 7000ms linear;
    animation: active-break-transition-1 7000ms linear;
  }
  @-webkit-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-moz-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -moz-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-o-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @-ms-keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
  @keyframes active-break-transition-1 {
    0% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    90% {
      margin-top: 14.1%;
      height: 68.9%;
      width: 100%;
      margin-left: 0%;
      object-fit: cover;
      z-index: 999;
      position: absolute;
    }
    100% {
      -webkit-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
    }
  }
}
