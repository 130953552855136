/* 9R Font */
@font-face {
  font-family: "Avenir";
  src: url("./fonts/Avenir.ttc");
}

@font-face {
  font-family: "Avenir Black";
  src: url("./fonts/Avenir\ Black.ttf");
}

@font-face {
  font-family: "Avenir Medium";
  src: url("./fonts/Avenir\ Medium.ttf");
}

/* Design */
.main {
  overflow: hidden;
}

.instructions {
  background-color: #c8102e;
  padding: 1% 0% 1% 1%;
  width: 101vw;
  height: 100vh;
  color: white;
  font-size: 2.7rem;
  font-family: "Avenir", "Roboto Mono", monospace;
  text-transform: uppercase;
  line-height: 2.9vh;
  overflow-x: hidden;
  letter-spacing: 1.5px;
  font-weight: bolder;
  text-shadow: 1px 1px 1px black;
}

.step {
  padding: 0% 3% 0.2% 2%;
  display: block;
}

.step-indent {
  margin-left: 1em;
  text-indent: -1.22em;
  line-height: 120%;
  margin-bottom: 0.8vh;
}

.sub-header {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: "Avenir Black", "Roboto Mono", monospace;
  font-weight: 900;
  font-size: 5rem;
  text-transform: uppercase;
  display: inline-block;
  color: #c8102e;
  line-height: 20%;
  margin: auto;
  padding: auto;
  position: relative;
  background-color: #ffff;
}

.no-sub-header {
  display: inline-block;
}

.spec-format {
  padding: 4% 5% 5% 4.5%;
}

/* Display Containers */
.screen-container {
  height: 101%;
  width: 101%;
  overflow: hidden;
}

.image-container {
  background-color: #c8102e;
  padding: 1% 0% 1.1% 0%;
  width: 100%;
  text-align: center;
}

/* Video Formatting */

.videos-3 .video-container {
  padding-top: 0.1%;
}

/* 3 Videos */
.videos-3 video {
  -webkit-transform: scaleX(1.125);
  -ms-transform: scaleX(1.125);
  transform: scaleX(1.125);
  height: 99%;
  width: 82%;
  margin-bottom: -6.5%;
  padding-bottom: 0%;
  object-fit: contain;
  background-color: black;
}

/* 2 Videos */
.videos-2 video {
  height: 34.5vh;
  width: 110%;
  object-fit: contain;
  position: relative;
  right: 1%;
}

.videos-2 .video-container {
  margin: -0.3% 3.5% -0.7% 0%;
  max-height: 100vh;
  max-width: 101vw;
}

/* 1 Video */
.videos-1 video {
  height: 69vh;
  object-fit: fill;
  margin-top: -0.3%;
  position: relative;
  right: 26%;
}

.videos-1 .video-container {
  margin-bottom: -0.5%;
}

/* Authentication styles */
.screen-auth-code {
  background-color: lightgrey;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 3rem;
  letter-spacing: 7px;
  color: var(--niner-color);
  font-weight: bold;
  font-family: "Roboto Mono", monospace;
}

/** Media Queries & Dynamic Resolution **/

/* Full 4k */
@media screen and (max-width: 2160px) and (min-width: 1441px) and (orientation: portrait) {
  .main {
    max-height: 3840px;
    max-width: 2160px;
  }
  .title .sub-header {
    font-size: 8.6rem;
    margin-top: 1.5%;
    margin-bottom: 1.4%;
    padding-top: 2%;
    padding-bottom: 1.2%;
  }
  .title .no-sub-header {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin-left: -0.5%;
    margin-bottom: 0%;
    height: 23vh;
    width: 100%;
  }
  .videos-3 video {
    margin: 0% 0% 0% 9%;
    padding-bottom: 0%;
  }

  .instructions p {
    font-size: 5rem;
    margin-top: 1%;
  }
  .image-container {
    padding-bottom: 1.8%;
  }
}

/* Quad HD */
@media screen and (max-width: 1440px) and (min-width: 1081px) and (orientation: portrait) {
  .main {
    max-height: 2560px;
    max-width: 1440px;
  }
  .title .sub-header {
    font-size: 6rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .title .no-sub-header {
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .videos-3 video {
    margin-top: -0.2%;
    margin-left: 10.55%;
    padding-bottom: 0%;
  }
  .videos-3 .video-container {
    margin-left: -2%;
    margin-top: 0.19%;
    margin-bottom: -0.2%;
    height: 23vh;
    width: 100%;
  }
  .videos-2 .video-container {
    padding-bottom: 0.5%;
    padding-top: 0.3%;
  }
  .instructions p {
    font-size: 3.3rem;
    margin-top: 1%;
  }
  .image-container {
    padding-bottom: 1.6%;
  }
}

/* Full HD */
@media screen and (max-width: 1080px) and (min-width: 721px) and (orientation: portrait) {
  .main {
    max-height: 1920px;
    max-width: 1080px;
  }
  .title .sub-header {
    font-size: 4.2rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.6%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin: -0.1% 0% -0.2% 1.3%;
    padding-bottom: 0%;
  }
  .videos-3 video {
    margin-left: 8%;
  }
  .videos-2 .video-container {
    padding-bottom: 0.3%;
    padding-top: 0.3%;
  }
  .videos-1 .video-container {
    margin-left: 2.4%;
  }
  .instructions p {
    font-size: 2.7rem;
    margin-top: 1%;
  }
}

/* Japan Spec */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .main {
    max-height: 1366px;
    max-width: 768px;
    overflow: hidden !important;
  }
  .title .sub-header {
    font-size: 2.8rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin: -0.1% 0% -0.2% 1.3%;
    padding-bottom: 0%;
  }
  .videos-3 video {
    margin-left: 8%;
    margin-bottom: -8%;
  }
  .videos-1 .video-container {
    margin-left: 2.4%;
  }
  .instructions p {
    font-size: 1.83rem;
    margin-top: 1%;
  }
}

/* SD */
@media screen and (max-width: 720px) and (orientation: portrait) {
  .main {
    max-height: 1280px;
    max-width: 720px;
  }
  .title .sub-header {
    font-size: 2.8rem;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .videos-3 .video-container {
    margin: -0.1% 0% -0.2% 1.3%;
    padding-bottom: 0%;
  }
  .videos-3 video {
    margin-left: 8%;
    margin-bottom: -8%;
  }
  .videos-1 .video-container {
    margin-left: 2.4%;
  }
  .instructions p {
    font-size: 1.7rem;
    margin-top: 1%;
  }
}

/* Landscape */
@media screen and (orientation: landscape) {
  .videos-1 video {
    height: 99%;
    width: 124%;
    margin-top: 0%;
    margin-bottom: 0.1%;
    margin-left: 0%;
  }
  .videos-1 .video-container {
    display: block;
    padding-left: 21%;
  }
  .videos-2 video {
    height: 95%;
    width: 99%;
    margin-top: 0%;
    margin-bottom: 0.1%;
  }
  .videos-3 video {
    height: 95%;
    width: 99%;
    margin-top: 0%;
    margin-bottom: 0.1%;
  }
  .video-container {
    display: contents;
  }
  .title .sub-header {
    font-size: 7vw;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }
  .instructions p {
    font-size: 3.5vw;
    padding-top: 2%;
    padding-left: 1%;
    line-height: 150%;
  }
  .step {
    padding-bottom: 2%;
  }
}

/** Animation Elements  **/

/* Header Scroll */
.ticker {
  white-space: nowrap;
}

.no-ticker {
  white-space: nowrap;
  text-align: center;
}

.ticker span {
  display: inline-block;
  padding-left: 101%;
  animation: scrollEffect 20s cubic-bezier(1, 1, 1, 1) infinite;
}
@keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-moz-keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-o-keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-ms-keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-webkit-keyframes scrollEffect {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Instructions Scroll */
.vertical-wrap {
  line-height: 3.5vh;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.vertical-scroll {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 33%;
  animation: verticalScroll 20s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
  display: inline-block;
}
@keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-moz-keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-o-keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-ms-keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes verticalScroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.flashingRed {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-color: darkred;
  z-index: 9999;
  -webkit-animation: flashing-red-screen 1000ms linear infinite;
  -moz-animation: flashing-red-screen 1000ms linear infinite;
  -ms-animation: flashing-red-screen 1000ms linear infinite;
  -o-animation: flashing-red-screen 1000ms linear infinite;
  animation: flashing-red-screen 1000ms linear infinite;
}
@keyframes flashing-red-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}

.flashingGreen {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-color: darkgreen;
  z-index: 9999;
  -webkit-animation: flashing-green-screen 1000ms linear infinite;
  -moz-animation: flashing-green-screen 1000ms linear infinite;
  -ms-animation: flashing-green-screen 1000ms linear infinite;
  -o-animation: flashing-green-screen 1000ms linear infinite;
  animation: flashing-green-screen 1000ms linear infinite;
}
@keyframes flashing-green-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}

.flashingYellow {
  position: absolute;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-color: goldenrod;
  z-index: 9999;
  -webkit-animation: flashing-yellow-screen 1000ms linear infinite;
  -moz-animation: flashing-yellow-screen 1000ms linear infinite;
  -ms-animation: flashing-yellow-screen 1000ms linear infinite;
  -o-animation: flashing-yellow-screen 1000ms linear infinite;
  animation: flashing-yellow-screen 1000ms linear infinite;
}
@keyframes flashing-yellow-screen {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.4;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.5;
  }
}

.screen-fx-hidden {
  display: none;
}

.screen-fx-image {
  height: 50%;
  width: 90%;
  margin-top: 40%;
}

.round-image {
  max-width: 70%;
  margin-top: -5%;
  margin-bottom: -5.5%;
}
