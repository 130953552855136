.SearchBar {
margin-left: auto;
padding-right: 1rem;

}

/* Override */

.SearchBar .form-control:focus {
  box-shadow: 0.2px 0.2px 0.2px 0.2px rgba(0.247, 0.247, 0.247, 0.247);
  outline: none;
}

.SearchBar .custom-select:focus {
  box-shadow: 0.2px 0.2px 0.2px 0.2px rgba(0.247, 0.247, 0.247, 0.247);
  outline: none;
}

.SearchBar .custom-select {
  border-radius: 0px;
}
