.card-v {
  border-radius: 2px;
  background: rgb(240, 240, 240);
  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.05), 0 0 6px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.5rem;
  margin-left: 5%;
  padding: 0.1rem 1rem 0.5rem 1rem;
  font-size: large;
  text-align: left;
  width: 90%;
  display: block;
  position: relative;
  max-height: 4rem;
  overflow-y: hidden;
  opacity: 0.7;
  user-select: none;
  cursor: pointer;
}

.card-v:hover {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3), 1px 2px 3px rgba(0, 0, 0, 0.04);
  opacity: 1;
}

.card-expand {
  overflow-y: show;
  position: relative;
  max-height: max-content;
  opacity: 1;
  padding: 0.1rem 1rem 0.5rem 1rem;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3), 1px 2px 3px rgba(0, 0, 0, 0.04);
}

.card-version {
  font-weight: 500;
  font-size: small;
  padding-bottom: 5vh;
}

.card-details {
  padding-top: 0.1rem;
}

.card-title {
  font-weight: bold;
  font-size: larger;
}

.card-description {
  font-size: large;
}

.card-expand > div > .card-description {
  text-decoration: underline;
  font-size: large;
}

.card-desc-details {
  font-size: large;
  display: inline;
}

.card-hidden {
  display: none;
}

/* Animations */
.card-expand-before {
  animation: expandCard linear 0.3s;
}
@-webkit-keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}
@-moz-keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}
@-o-keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}
@-ms-keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}
@keyframes expandCard {
  0% {
    max-height: 7.8rem;
  }
  100% {
    max-height: 30rem;
  }
}

.card-expand-after {
  animation: retractCard linear 0.1s;
}
@-webkit-keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}
@-moz-keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}
@-o-keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}
@-ms-keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}
@keyframes retractCard {
  0% {
    max-height: 30rem;
  }
  100% {
    max-height: 7.8rem;
  }
}

